import Header from "./Header";
import Footer from "./Footer";
import "./Checkout.css";
import ShippingPayment from "./ShippingPayment";
import ReviewOrder from "./ReviewOrder";
import OrderComplete from "./OrderComplete";
import { useStore } from "../store/home";
import NavigationBar from "../commenComp/NavigationBar";
import { useMutation } from "@tanstack/react-query";
import OrderSummary from "./OrderSummary";
import ShippingPaymentComponent from "./ShippingPaymentComponent";
import { useEffect, useState } from "react";
import { getMyCart, getMyProfile } from "../api/posts";
import Loader from "../commenComp/Loader";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
  const {
    checkoutState,myProfile,
    setMyProfile,
    setCheckoutState,
    dealerId,
    userInfo,
    token,
    myCart,
  } = useStore();
  useEffect(() => {
    setCheckoutState("ship");
  }, []);

  const { setLoad } = useNonPersistedStore();
  const [dataArray, setDataArray] = useState([]);
  const navigate = useNavigate();

  const mutation = useMutation((post) => getMyProfile(post, token), {
    onSuccess: (result) => {
      setMyProfile(result?.data);
    },
    onError: (error) => {},
  });
  const mutation1 = useMutation((post) => getMyCart(post, token), {
    mutationKey: "getMyCart",
    onSuccess: (result) => {
      setDataArray(result.data.data);
    },
    onError: (error) => {},
  });

  useEffect(()=>{
    !myProfile?.profileInfo?.epicorDealerId && navigate("/cart")
  },[])

  useEffect(() => {
    const obj = {};
    obj.email = userInfo?.username;
    obj.dealerId = dealerId;
    setDataArray(myCart)
    // mutation.mutate(obj);
    // mutation1.mutate(obj);
  }, []);
  const handleRefreshCallBack = () => {
    let data = {
      email: userInfo?.username,
      dealerId: dealerId,
    };
    mutation1.mutate(data);
  };

  useEffect(() => {
    if (mutation1.isLoading) {
      setLoad(true);
    } else {
      setLoad(false);
    }
  }, [mutation1.isLoading]);

  return (
    <div className="checkOutMainDiv">
      {/* {mutation1.isLoading && <Loader></Loader>} */}
      <div className="checkoutDiv">
        <Header page="Catalog"></Header>
        <NavigationBar />
      </div>
      <div className="CheckoutComponent">
        {checkoutState === "ship" && (
          <ShippingPaymentComponent
            reviewOrder={true}
            handleRefreshCallBack={handleRefreshCallBack}
            dataArray={dataArray}
          ></ShippingPaymentComponent>
        )}
        {checkoutState === "review" && (
          <ShippingPaymentComponent
            reviewOrder={false}
            handleRefreshCallBack={handleRefreshCallBack}
            dataArray={dataArray}
          ></ShippingPaymentComponent>
        )}
        {checkoutState === "order" && <OrderComplete></OrderComplete>}
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Checkout;
