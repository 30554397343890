import React, { useEffect, useState, useRef } from "react";
import { useStore } from "../store/home";
import { useNonPersistedStore } from "../store/NonPersistedStore";

import "./Header.css";
import logo from "../Images/ILMOR_BLACK.png";
import shoppingList from "../Images/shoppingList.svg";
import searchIcon from "../Images/shoppingListGrey.svg";
import redCart from "../Images/redCartDot.svg";
import shopCart from "../Images/shopCart.svg";
import account from "../Images/account.svg";
import search from "../Images/searchIcon.svg";

import {} from "@tanstack/react-query";
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Grid } from "@material-ui/core";
import InputBase from "@mui/material/InputBase";
import { useMutation } from "@tanstack/react-query";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "../Images/searchIcon.svg";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import Banner from "./Banner";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import loaderImg from "../Images/loaderImg.gif";
import { useNavigate, useLocation } from "react-router-dom";
import { getMyCart, getPartList } from "../api/posts";
import Loader from "../commenComp/Loader";
import ShopCart from "./ShopCart";
import classNames from "classnames";
import IlmorModal from "../commenComp/IlmorModal";
import { customStyles } from "../utils/ReactSelectCustomStyles";
import { initialState } from "../commonFunction";

const Header = ({ page, no }) => {
  const {
    token,
    setPartListArray,
    setKeyWord,
    setSideMenuAccount,
    setSelectedCategory,
    keyWord,
    setCategoryIdSearch,
    setKeyWordFinal,
    categoryId,
    setCategoryId,
    categoryOptions,
    unsaved,
    setIsCard,
    currentDevice,
    myCartMini,
    setMyCartMini,
    setUnsaved,
    userInfo,
    setStopNavigate,
    setGlobalcategoryIdCatalog,
    globalcategoryIdCatalog,
    setToken,
    dealerId,
    isCurrentEngSet,
    setFooterContentdataInZustand,
    myProfile,
    setRecord,
    setTRecord,
    setLastPage,
    partListArray,
    setSort,
    setOffset,
  } = useStore();
  const [age, setAge] = React.useState("");
  const [okToNavigate, setOkToNavigate] = useState(false);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [param, setParam] = useState({});
  const [input, setInput] = useState("");
  const divRef = useRef(null);
  const location = useLocation();
  const divRefShop = useRef(null);
  const divRefSearch = useRef(null);
  const { setHeadLoad, setViaLink, setRefreshQ, refreshQ } =
    useNonPersistedStore();
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isShopCartOpen, setisShopCartOpen] = useState(false);
  const [isShopCartOpen1, setisShopCartOpen1] = useState(false);
  const handleAccountMenu = () => {
    closeAll();
    setIsAccountMenuOpen(!isAccountMenuOpen);
  };

  useEffect(() => {
    if (isShopCartOpen || isShopCartOpen1) {
      setIsAccountMenuOpen(false);
    }
  }, [isShopCartOpen, isShopCartOpen1]);
  useEffect(() => {
    let handler = (e) => {
      if (!divRef?.current?.contains(e.target)) {
        setIsAccountMenuOpen(false);
      }
      if (!divRefShop?.current?.contains(e.target)) {
        setisShopCartOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const handleSearchMenu = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const resetStore = () => {
    useStore.setState(initialState);
  };
  const handleSignOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("lastUrl")
    setLastPage(false);
    localStorage.setItem("my-persisted-store", "");
    localStorage.removeItem("my-persisted-store");
    resetStore();
    setSideMenuAccount("home");
    setSelectedCategory("home");
    setFooterContentdataInZustand([]);
    setToken("");
    navigate("/sign-in", { replace: true });
  };

  const handleMyAccount = (data) => {
    setStopNavigate(true);
    setIsAccountMenuOpen(false);
    setSideMenuAccount(data);
    navigate({
      pathname: "/account",
    });
  };
  const mutation = useMutation((post) => getPartList(post), {
    mutationKey: "getPartList",
    method: "GET",
    onSuccess: (result) => {
      setRefreshQ(!refreshQ);
      setPartListArray(result.data.data);
      setTRecord(result?.data?.total);
      setRecord(result?.data?.perPage);
      setKeyWordFinal(keyWord);
      setCategoryIdSearch(categoryId);
      if (okToNavigate) {
        navigate({
          pathname: "/search",
          search: `engineId=${
            isCurrentEngSet?.engineId ?? ""
          }&s=${isCurrentEngSet ? "comp" : "partNumber"}&keyWord=${keyWord}&category=${categoryId.value ?? ""}`,
        });
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if (mutation.error?.message.includes("401")) {
      setLastPage(false);
      localStorage.removeItem("token");
      resetStore();
      navigate("/sign-in", { replace: true });
    }
  }, [mutation.error]);

  useEffect(() => {
    if (mutation.isLoading) {
      setHeadLoad(true);
    } else {
      setHeadLoad(false);
    }
  }, [mutation.isLoading]);

  // const handleSearch1 = () => {
  //   setPartListArray([])
  //   const params = {
  //     token: token,
  //     categoryId: categoryId ? categoryId.value : "",
  //     subCategoryId: "",
  //     keyword: keyWord,
  //     engineId: isCurrentEngSet?.engineId ?? "",
  //     dealerId: dealerId,
  //     perPage: 20,
  //     offset: offset,
  //     sortBy: sort,
  //   };
  //   mutation.mutate(params);
  //   setGlobalcategoryIdCatalog(categoryId ? categoryId.value : "");
  //   // setIsCard(true);
  //   setIsSearchOpen(false);
  //   setOkToNavigate(true);
  // };

  const handleSearch = () => {
    setViaLink(false);
    setSort(isCurrentEngSet ? "comp" : "partNumber");
    let arr = [];
    if (partListArray && partListArray.length > 0) {
      arr = partListArray.map((item) => {
        item.images = loaderImg;
        item.isLoad = true;
        return item;
      });
    }
    setRefreshQ(!refreshQ);
    setPartListArray(arr);
    setTRecord(0);
    setRecord(0);
    setOffset(0);
    const params = {
      token: token,
      categoryId: categoryId ? categoryId.value : "",
      subCategoryId: "",
      keyword: keyWord,
      engineId: isCurrentEngSet?.engineId ?? "",
      dealerId: dealerId,
      perPage: 20,
      offset: 0,
      sortBy: isCurrentEngSet ? "comp" : "partNumber",
    };
    mutation.mutate(params);
    setGlobalcategoryIdCatalog(categoryId ? categoryId.value : "");
    // setIsCard(true);
    setIsSearchOpen(false);
    setOkToNavigate(true);
  };

  const mutation_cart = useMutation((post) => getMyCart(post, token), {
    onSuccess: (result) => {
      setMyCartMini(result);
    },
    onError: (error) => {},
  });
  useEffect(() => {
    const obj = {
      email: userInfo.username,
      minicart: true,
      dealerId: dealerId,
    };
    // mutation_cart.mutate(obj);
  }, []);

  const [dot, setDot] = useState();
  useEffect(() => {
    setDot(myCartMini?.data?.minicart?.count);
  }, [myCartMini]);

  useEffect(() => {
    categoryOptions?.forEach((obj, id) => {
      if (obj?.value == globalcategoryIdCatalog) {
        setGlobalcategoryIdCatalog(obj?.label);
      }
    });
  }, [globalcategoryIdCatalog]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  const handleShopCart = (event) => {
    setisShopCartOpen(!isShopCartOpen);
  };
  const handleChange = (data) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("category", data.value);
    const newSearchParams = queryParams.toString();
    navigate({
      search: `?${newSearchParams}`, // Include the '?' to start the query string
    });
    setCategoryId(data);
  };

  const handleShopCart1 = (event) => {
    setisShopCartOpen1(event);
  };

  const closeAll = () => {
    setisShopCartOpen(false);
    setisShopCartOpen1(false);
  };
  const [searchParams] = useSearchParams();
  const [cancelDialog, setCancelDialog] = useState(false);
  const handleModal = () => {
    if (unsaved && !cancelDialog) {
      setCancelDialog(true);
    }
  };
  const handleClose = () => {
    setUnsaved(false);
    setCancelDialog(false);
  };
  const handleOkay = () => {
    const buttonElement = document.getElementById("shopListSave");
    if (buttonElement) {
      buttonElement.click();
    }
    setUnsaved(false);
    setCancelDialog(false);
  };
  return (
    <div onClick={handleModal}>
      <IlmorModal
        isopen={cancelDialog}
        handleOkay={handleOkay}
        handleClose={handleClose}
        message={"You have unsaved changes in your shopping list"}
        messageDetail={"Use Save button to save changes"}
        fisrtButton={"Don't Save"}
        secondButton={"Save"}
      ></IlmorModal>
      {/* {mutation.isLoading && <Loader></Loader>} */}
      <div
        className={`${page !== "Catalog" ? "header" : "headerCatalog"}${
          page === "MyAccount" ? " myAccountHeader" : ""
        }${page === "YourCart" ? " yourCart" : ""}${
          page === "CatalogPage" ? "catalogPage" : ""
        }`}
      >
        <Grid
          className={`headerGrid${
            currentDevice === "Mobile" || currentDevice === "Tablet"
              ? "Mobile"
              : ""
          } ${page === "MyAccount" ? " myAccountHeaderGrid" : ""}`}
        >
          <div
            className={classNames("main__body", {
              disabledHeader: unsaved,
            })}
          >
            <div
              className={`top__bar${
                currentDevice === "Mobile" ? "Mobile" : ""
              }`}
            >
              <div
                className={`header__leftside__options${
                  currentDevice === "Mobile" ? "Mobile" : ""
                }${currentDevice === "Tablet" ? "Tablet" : ""}`}
              >
                <img
                  className={`header__image${
                    currentDevice === "Mobile" ? "Mobile" : ""
                  }`}
                  onClick={() => {
                    navigate({
                      pathname: "/",
                    });
                  }}
                  src={logo}
                  alt="Ilmor Logo"
                />
                {currentDevice === "Desktop" && (
                  <div
                    tabIndex={"0"}
                    onKeyDown={handleKeyDown}
                    className="header__white__section"
                    style={{
                      color: "black",
                      display: "flex",
                      backgroundColor: "white",
                    }}
                  >
                    <Select
                      styles={customStyles}
                      //  className="header__select"
                      // defaultValue={'189'}
                      value={
                        searchParams?.get("category")
                          ? categoryOptions.filter(
                              (item) =>
                                item?.value ===
                                parseInt(searchParams?.get("category"))
                            )
                          : categoryOptions.filter(
                              (item) => item?.value === categoryId.value
                            )
                      }
                      placeholder="All Products"
                      onChange={handleChange}
                      // menuIsOpen={true}
                      options={categoryOptions}
                    />
                    <div className="header__select__bar"></div>
                    <InputBase
                      style={{
                        backgroundColor: "white",
                        border: "none",
                        outline: "none",
                        padding: "5px",
                        fontSize: "12px !important",
                        // fontStyle: "italic",
                      }}
                      placeholder="Enter keyword or part number"
                      onChange={(e) => {
                        setKeyWord(e.target.value);
                      }}
                      value={keyWord}
                      className="header__input__base"
                      inputProps={{ "aria-label": "search google maps" }}
                    />
                    {/* <IconButton onClick={handleSearch} type="button" sx={{ p: '10px' }} aria-label="search">
                                    <SearchIcon color='#CE0E2D' />
                                </IconButton> */}

                    {/* <img  className='searchGrey' style={{marginRight : '10px'}} onClick={handleSearch} src={searchIcon} ></img> */}

                    <img
                      className="searchRed"
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={handleSearch}
                      src={search}
                    ></img>
                  </div>
                )}

                {currentDevice === "Mobile" && (
                  <div className="header___MobileTopMenu">
                    {/* <img onClick={handleSearch} src={search} ></img> */}
                    <div
                      onClick={handleSearchMenu}
                      className={`searchDiv hwSearch ${
                        isSearchOpen ? " backGrey" : ""
                      }`}
                    >
                      {!isSearchOpen ? (
                        <img
                          style={{ cursor: "pointer" }}
                          src={searchIcon}
                        ></img>
                      ) : (
                        <img src={search}></img>
                      )}
                    </div>
                    <div className="header__select__bar"></div>

                    {isSearchOpen && (
                      <div className="accountPopMenuSearch fullWidthSearc">
                        <div className={`accountMenuOpenMobile fullWidthSearc`}>
                          <Select
                            // className="header__select fullWidthSearc mobileNewDiVPAdding"
                            // defaultValue={selectedOption}
                            value={
                              searchParams?.get("category")
                                ? categoryOptions.filter(
                                    (item) =>
                                      item?.value ===
                                      parseInt(searchParams?.get("category"))
                                  )
                                : categoryOptions.filter(
                                    (item) => item?.value === categoryId.value
                                  )
                            }
                            placeholder="All Products"
                            styles={customStyles}
                            onChange={handleChange}
                            // menuIsOpen={true}
                            options={categoryOptions}
                          />
                          <div
                            onKeyDown={handleKeyDown}
                            className="inputBaseDiv"
                          >
                            <InputBase
                              style={{
                                backgroundColor: "white",
                                border: "none",
                                outline: "none",
                                padding: "5px",
                                fontStyle: "italic",
                              }}
                              placeholder="Enter keyword or part number"
                              onChange={(e) => {
                                setKeyWord(e.target.value);
                              }}
                              value={keyWord}
                              className="header__input__baseMobile"
                              inputProps={{
                                "aria-label": "search google maps",
                              }}
                            />
                            <img
                              className="searchRed"
                              style={{ marginRight: "10px", cursor: "pointer" }}
                              onClick={handleSearch}
                              src={search}
                            ></img>
                          </div>
                        </div>
                        {/* <div className='arrow'></div> */}
                      </div>
                    )}
                    <img
                      src={shoppingList}
                      style={{}}
                      onClick={() => {
                        navigate({
                          pathname: "/shopping-list",
                        });
                      }}
                    ></img>
                    {!dealerId && <div className="header__select__bar"></div>}

                    {/* <img style={{ marginRight: '16px', width: '23px' }} src={shopCart} ></img> */}
                    {myProfile?.profileInfo?.role !== "ServiceTechnician" && !dealerId &&  (
                      <div className="header__text" ref={divRefShop}>
                        {/* <img src={shopCart} onMouseLeave={()=>{handleShopCart(false)}} onMouseEnter={()=>{handleShopCart(true)}} ></img>  */}
                        {/* <img src={shopCart} onClick={handleShopCart}></img> */}
                        {!dot ? (
                          <img src={shopCart} onClick={handleShopCart}></img>
                        ) : (
                          <img src={redCart} onClick={handleShopCart}></img>
                        )}
                        {isShopCartOpen && (
                          <div className="ShopCartMenu">
                            <div className={`ShopCartOpen${currentDevice}`}>
                              <div>
                                <ShopCart closeAll={closeAll} />
                              </div>
                            </div>
                            <div className="shopCart__arrow"></div>
                          </div>
                        )}
                      </div>
                    )}
                    {myProfile?.profileInfo?.role !== "ServiceTechnician" && (
                      <div className="header__select__bar"></div>
                    )}

                    <div className="accountFlexDiv" ref={divRef}>
                      <img
                        style={{ width: "23px", marginRight: "12px" }}
                        onClick={handleAccountMenu}
                        src={account}
                      ></img>
                      {isAccountMenuOpen && (
                        <div className="accountPopMenu">
                          <div className={`accountMenuOpenDesktop`}>
                            <div
                              onClick={() => {
                                // handleMyAccount("home");
                                setIsAccountMenuOpen(false);
                                navigate({
                                  pathname: "/account",
                                });
                              }}
                              className="accountMenuOptionsDiv"
                            >
                              <p className="accountMenuOptions">Account Home</p>
                            </div>
                            <div
                              onClick={() => {
                                // handleMyAccount("orderDefault");
                                setIsAccountMenuOpen(false);
                                navigate({
                                  pathname: "/account/orders",
                                });
                              }}
                              className="accountMenuOptionsDiv"
                            >
                              <p className="accountMenuOptions">
                                Order Status & History
                              </p>
                            </div>
                            <div
                              onClick={() => {
                                handleMyAccount("employee");
                                setIsAccountMenuOpen(false);
                                navigate({
                                  pathname: "/account/mgmt/accounts",
                                });
                              }}
                              className="accountMenuOptionsDiv"
                            >
                              <p className="accountMenuOptions">
                                Account Management
                              </p>
                            </div>
                            <div
                              onClick={() => {
                                handleMyAccount("myProfile");
                              }}
                              className="accountMenuOptionsDiv"
                            >
                              <p className="accountMenuOptions">My Account</p>
                            </div>
                            <div
                              onClick={handleSignOut}
                              className="accountMenuOptionsDiv"
                            >
                              <p className="accountMenuOptions">Sign Out</p>
                            </div>
                          </div>
                          <div className="arrow"></div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {currentDevice === "Tablet" && (
                  <div className="header__rightside__optionsTablet">
                    <span
                      onClick={() => {
                        navigate({
                          pathname: "/partscatalog",
                        });
                      }}
                      className="header__text__menuMobile"
                    >
                      Catalog
                    </span>
                    <div className="header__select__bar"></div>
                    <span
                      className="header__text__menuMobile"
                      onClick={() => {
                        setKeyWordFinal("");
                        navigate({
                          pathname: "/quick-order-form",
                        });
                      }}
                    >
                      Quick Order
                    </span>
                    <div className="header__select__bar"></div>
                    {/* <img className='searchRed' onClick={handleSearch} src={search} ></img> */}
                    {/* <img className='searchGrey' style={{ marginRight: '10px' }} onClick={handleSearch} src={searchIcon} ></img> */}
                    <div
                      className={`searchDiv hwTabletSearch ${
                        isSearchOpen ? " backGrey" : ""
                      }`}
                    >
                      {!isSearchOpen ? (
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={handleSearchMenu}
                          src={searchIcon}
                        ></img>
                      ) : (
                        <img onClick={handleSearchMenu} src={search}></img>
                      )}
                    </div>
                    <div className="header__select__bar"></div>
                    {isSearchOpen && (
                      <div className="accountPopMenuSearch fullWidthSearc">
                        <div className={`accountMenuOpenMobile fullWidthSearc`}>
                          <Select
                            // className="header__select fullWidthSearc rtrt3 tabletviewMinWidth"
                            // defaultValue={selectedOption}
                            value={
                              searchParams?.get("category")
                                ? categoryOptions.filter(
                                    (item) =>
                                      item?.value ===
                                      parseInt(searchParams?.get("category"))
                                  )
                                : categoryOptions.filter(
                                    (item) => item?.value === categoryId.value
                                  )
                            }
                            styles={customStyles}
                            placeholder="All Products"
                            onChange={handleChange}
                            // menuIsOpen={true}
                            options={categoryOptions}
                          />
                          <div
                            onKeyDown={handleKeyDown}
                            className="inputBaseDiv"
                          >
                            <InputBase
                              style={{
                                backgroundColor: "white",
                                border: "none",
                                outline: "none",
                                padding: "5px",
                                fontStyle: "italic",
                              }}
                              placeholder="Enter keyword or part number"
                              onChange={(e) => {
                                setKeyWord(e.target.value);
                              }}
                              value={keyWord}
                              className="header__input__baseMobile"
                              inputProps={{
                                "aria-label": "search google maps",
                              }}
                            />
                            <img
                              className="searchRed"
                              style={{ marginRight: "10px", cursor: "pointer" }}
                              onClick={handleSearch}
                              src={search}
                            ></img>
                          </div>
                        </div>
                        {/* <div className='arrow'></div> */}
                      </div>
                    )}
                    <div className="header__shoppingListMobile">
                      <img
                        src={shoppingList}
                        onClick={() => {
                          navigate({
                            pathname: "/shopping-list",
                          });
                        }}
                      ></img>
                    </div>
                    {!dealerId && <div className="header__select__bar"></div>}

                    {/* <div className='header__shoppingListMobile'>
                                        <img src={shopCart} onClick={() => {
                                            navigate({
                                                pathname: "/addtocart",
                                            });
                                        }} ></img>
                                    </div> */}
                    {myProfile?.profileInfo?.role !== "ServiceTechnician" && !dealerId && (
                      <div className="header__text" ref={divRefShop}>
                        {/* <img src={shopCart} onMouseLeave={()=>{handleShopCart(false)}} onMouseEnter={()=>{handleShopCart(true)}} ></img>  */}
                        {/* <img src={shopCart} onClick={handleShopCart}></img> */}
                        {!dot ? (
                          <img src={shopCart} onClick={handleShopCart}></img>
                        ) : (
                          <img src={redCart} onClick={handleShopCart}></img>
                        )}
                        {isShopCartOpen && (
                          <div className="ShopCartMenu">
                            <div className={`ShopCartOpen${currentDevice}`}>
                              <div>
                                <ShopCart closeAll={closeAll} />
                              </div>
                            </div>
                            <div className="shopCart__arrow"></div>
                          </div>
                        )}
                      </div>
                    )}
                    {myProfile?.profileInfo?.role !== "ServiceTechnician" && (
                      <div className="header__select__bar"></div>
                    )}
                    <div className="accountFlexDiv" ref={divRef}>
                      <img onClick={handleAccountMenu} src={account}></img>
                      {isAccountMenuOpen && (
                        <div className="accountPopMenu">
                          <div className={`accountMenuOpenDesktop`}>
                            <div
                              onClick={() => {
                                // handleMyAccount("home");
                                setIsAccountMenuOpen(false);
                                navigate({
                                  pathname: "/account",
                                });
                              }}
                              className="accountMenuOptionsDiv"
                            >
                              <p className="accountMenuOptions">Account Home</p>
                            </div>
                            <div
                              onClick={() => {
                                navigate({
                                  pathname: "/account/orders",
                                });
                                // handleMyAccount("orderDefault");
                                setIsAccountMenuOpen(false);
                              }}
                              className="accountMenuOptionsDiv"
                            >
                              <p className="accountMenuOptions">
                                Order Status & History
                              </p>
                            </div>
                            <div
                              onClick={() => {
                                handleMyAccount("employee");
                                setIsAccountMenuOpen(false);
                                navigate({
                                  pathname: "/account/mgmt/accounts",
                                });
                              }}
                              className="accountMenuOptionsDiv"
                            >
                              <p className="accountMenuOptions">
                                Account Management
                              </p>
                            </div>
                            <div
                              onClick={() => {
                                handleMyAccount("myProfile");
                              }}
                              className="accountMenuOptionsDiv"
                            >
                              <p className="accountMenuOptions">My Account</p>
                            </div>
                            <div
                              onClick={handleSignOut}
                              className="accountMenuOptionsDiv"
                            >
                              <p className="accountMenuOptions">Sign Out</p>
                            </div>
                          </div>
                          <div className="arrow"></div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {currentDevice === "Desktop" && token && (
                <div className="header__rightside__options">
                  <span
                    onClick={() => {
                      navigate({
                        pathname: "/partscatalog",
                      });
                    }}
                    className="header__text"
                  >
                    Catalog
                  </span>
                  <div className="header__select__bar"></div>
                  <span
                    style={{ minWidth: "88px" }}
                    className="header__text"
                    onClick={() => {
                      setKeyWordFinal("");
                      navigate({
                        pathname: "/quick-order-form",
                      });
                    }}
                  >
                    Quick Order
                  </span>
                  <div className="header__select__bar"></div>

                  <div className="header__shoppingList">
                    <img
                      src={shoppingList}
                      onClick={() => {
                        navigate({
                          pathname: "/shopping-list",
                        });
                      }}
                    ></img>
                  </div>
                  {!dealerId && <div className="header__select__bar"></div>}

                  {myProfile?.profileInfo?.role !== "ServiceTechnician" && !dealerId && (
                    <div
                      onClick={() => {
                        navigate({
                          pathname: "/cart",
                        });
                      }}
                      className="header__text"
                    >
                      {!dot ? (
                        <img
                          src={shopCart}
                          onMouseEnter={() => {
                            handleShopCart1(true);
                          }}
                        ></img>
                      ) : (
                        <img
                          src={redCart}
                          onMouseEnter={() => {
                            handleShopCart1(true);
                          }}
                        ></img>
                      )}
                      {/* <img src={shopCart} onClick={handleShopCart}></img> */}
                      {isShopCartOpen1 && (
                        <div
                          className="ShopCartMenu"
                          onMouseLeave={() => {
                            handleShopCart1(false);
                          }}
                        >
                          <div className={`ShopCartOpen${currentDevice}`}>
                            <div>
                              <ShopCart closeAll={closeAll} />
                            </div>
                          </div>
                          <div className="shopCart__arrow"></div>
                        </div>
                      )}
                      {/* <img src={shopCart} onClick={() => {
                                        navigate({
                                            pathname: "/cart",
                                        });
                                    }} ></img> */}
                    </div>
                  )}
                  {myProfile?.profileInfo?.role !== "ServiceTechnician" && (
                    <div className="header__select__bar"></div>
                  )}

                  <div ref={divRef} className="header__account__icon">
                    <img onClick={handleAccountMenu} src={account}></img>
                    {isAccountMenuOpen && (
                      <div className="accountPopMenu">
                        <div className={`accountMenuOpen${currentDevice}`}>
                          <div
                            onClick={() => {
                              // handleMyAccount("home");
                              setIsAccountMenuOpen(false);
                              navigate({
                                pathname: "/account",
                              });
                            }}
                            className="accountMenuOptionsDiv"
                          >
                            <p className="accountMenuOptions">Account Home</p>
                          </div>
                          <div
                            onClick={() => {
                              navigate({
                                pathname: "/account/orders",
                              });
                              setIsAccountMenuOpen(false);
                              // handleMyAccount("orderDefault");
                            }}
                            className="accountMenuOptionsDiv"
                          >
                            <p className="accountMenuOptions">
                              Order Status & History
                            </p>
                          </div>
                          <div
                            onClick={() => {
                              handleMyAccount("employee");
                              setIsAccountMenuOpen(false);
                              navigate({
                                pathname: "/account/mgmt/accounts",
                              });
                            }}
                            className="accountMenuOptionsDiv"
                          >
                            <p className="accountMenuOptions">
                              Account Management
                            </p>
                          </div>
                          <div
                            onClick={() => {
                              handleMyAccount("myProfile");
                            }}
                            className="accountMenuOptionsDiv"
                          >
                            <p className="accountMenuOptions">My Account</p>
                          </div>
                          <div
                            onClick={handleSignOut}
                            className="accountMenuOptionsDiv"
                          >
                            <p className="accountMenuOptions">Sign Out</p>
                          </div>
                        </div>
                        <div className="arrow"></div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {currentDevice === "Mobile" && (
                <div className="header__rightside__optionsMobile">
                  <span
                    onClick={() => {
                      navigate({
                        pathname: "/partscatalog",
                      });
                    }}
                    className="header__text__menuMobile left"
                  >
                    Catalog
                  </span>
                  <div className="header__select__bar"></div>
                  <span
                    className="header__text__menuMobile right"
                    onClick={() => {
                      setKeyWordFinal("");
                      navigate({
                        pathname: "/quick-order-form",
                      });
                    }}
                  >
                    Quick Order
                  </span>
                </div>
              )}
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default Header;
