import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
    const auth1 = localStorage.getItem('token') && (localStorage.getItem('token') || !sessionStorage.getItem('timeout'))
    if(!auth1 && (window.location.pathname)!="/sign-in")
     {localStorage.setItem(
     'lastUrl',window.location.pathname + window.location.search
 )}
 const auth = localStorage.getItem('token') || !sessionStorage.getItem('timeout')
    return auth ? <Outlet /> :  <Navigate to="/sign-in" /> ||<Navigate to="/forgot-password"/> || <Navigate to="/reset-password"/>;
}

export default PrivateRoute