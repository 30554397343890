import React, { useRef } from "react";
import { useState } from "react";
import Header from "./Header";
import { ShoppingListComponent } from "./ShoppingListComponent";
import OrderSummary from "./OrderSummary";
import "./YourCart.css";
import SubHeading from "../commenComp/SubHeading";
import { useStore } from "../store/home";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getMyCart, getMyProfile } from "../api/posts";
import { useMutation } from "@tanstack/react-query";
import Loader from "../commenComp/Loader";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { showErrorToast } from "../utils/Toaster";
import { CartViewMobile } from "./CartViewMobile";
import Footer from "./Footer";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

export default function YourCart() {
  const {
    currentDevice,
    userInfo,
    token,
    setMyCartMini,
    setKeyWord,
    setKeyWordFinal,
    dealerId,
    setMyProfile,
    setMyCart,
    myProfile,
    setshipPrice,
    setservice
  } = useStore();
  const [dataArray, setDataArray] = useState([]);
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const {
    setTempSummary,
    setShippingPaymentAddressUpdated,
    setChange,
    setfailMandate,
    setLoadCArt1,
    setquant,
    setCload,
  } = useNonPersistedStore();
  // const debouncedRefreshCallback = debounce((prop) => {
  // showErrorToast(prop);
  // }, 1000);
  const mutation1 = useMutation((post) => getMyCart(post, token), {
    mutationKey: "getMyCart",
    onSuccess: (result) => {
      setLoadCArt1(false);
      setLoad(false);
      setMyCart(result.data.data);
      if (result?.data?.data?.shippingChargeError) {
        // showErrorToast(`${result?.data?.data?.shippingChargeError}`);
      }
      if (result?.data?.couponError) {
        showErrorToast(`${result?.data?.couponError}`);
      } 
      setfailMandate(true);
      setquant(true);
      if (result?.data?.data?.items && result?.data?.data?.items.length > 0) {
        result?.data?.data?.items.forEach((item1) => {
          if (
            item1?.otherNotes &&
            Object.values(item1?.otherNotes).length > 0
          ) {
            Object.values(item1?.otherNotes).forEach((item2) => {
              if (item2.length > 0) {
                item2.forEach((item3) => {
                  if (!item3.value) {
                    setfailMandate(false);
                  }
                });
              }
            });
          }
          if ((item1?.inventory-item1?.inStockQuantity) < item1.partQty && !item1.isBackordered) {
            setquant(false);
          }
        });
      }
      setChange(true);
      setDataArray(result.data.data);
    },
    onError: (error) => {
      setLoad(false);
    },
  });

  useEffect(() => {
   if(myProfile?.profileInfo?.role === "ServiceTechnician"){
    navigate("/error");
   }
   setCload(false)
   setshipPrice('')
  }, []);

  const handleRefreshCallBack = () => {
    let data = {
      dealerId: dealerId,
      email: userInfo?.username,
    };
    setLoadCArt1(true);
    mutation1.mutate(data);
  };
  const mutation_cart = useMutation((post) => getMyCart(post, token), {
    onSuccess: (result) => {
      setLoad(false);
      setMyCartMini(result);
    },
    onError: (error) => {
      setLoad(false);
    },
  });

  const mutation = useMutation((post) => getMyProfile(post, token), {
    onSuccess: (result) => {
      setMyProfile(result?.data);
    },
    onError: (error) => {},
  });
  const initialRender = useRef(true);

  useEffect(() => {
    setKeyWordFinal("");
    setKeyWord("");
    let data = {
      dealerId: dealerId,
      email: userInfo?.username,
    };
    if (initialRender.current) {
      initialRender.current = false;
      setLoad(true);
      mutation.mutate(data);
      mutation1.mutate(data);
      const obj = {
        email: userInfo.username,
        dealerId: dealerId,
        minicart: true,
      };
      // mutation_cart.mutate(obj);
      setTempSummary(false);
      setShippingPaymentAddressUpdated({});
      return;
    }
  }, []);

  const [dots, setDots] = useState(1);
  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots < 4 ? prevDots + 1 : 1));
    }, 100); // Adjust the interval duration as needed
    // setAllow(true)
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {/* {load && (
        <>
          <Loader></Loader>
          <Loader></Loader>
        </>
      )} */}
      <Grid container>
        <Grid container className="yourCart_mainContainer">
          <Grid
            item
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="yourCart_headerContainer"
          >
            <Header page="YourCart" />
            <SubHeading yourCart={true} heading={"YOUR CART"} />
          </Grid>
          {!load ? (
            <Grid
              container
              spacing={4}
              className="yourCart_bodyContainer yuyu"
              sx={{
                minHeight: "calc(100vh  - 387px) !important",
                //  minHeight: "760px",
              }}
            >
              {(!dataArray?.items || dataArray?.items?.length === 0) && (
                <Grid
                  item
                  md={12}
                  lg={12}
                  sm={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  xs={12}
                  className="shoppingList_yourCart"
                >
                  <div className="center cartEmpty">
                    <RemoveShoppingCartIcon
                      style={{ fontSize: 100 }}
                    ></RemoveShoppingCartIcon>
                    <div className="cartEmptyMessage">your cart is empty</div>
                  </div>
                </Grid>
              )}
                      { !myProfile?.profileInfo?.epicorDealerId ? <h3 className="withoutDealerId"><ReportGmailerrorredIcon/>Your Dealer ID is not associated with your account, please contact ILMOR Admin. </h3>
     :null }
              <Grid item md={9} xs={12} className="shoppingList_yourCart">
                {currentDevice === "Desktop" && (
                  <ShoppingListComponent
                    reviewOrder={false}
                    yourCart={true}
                    dataArray={dataArray?.items}
                    handleRefreshCallBack={handleRefreshCallBack}
                    isCart={true}
                    isAutoApi={true}
                  ></ShoppingListComponent>
                )}
                {currentDevice === "Tablet" && (
                  <ShoppingListComponent
                    reviewOrder={false}
                    handleRefreshCallBack={handleRefreshCallBack}
                    isCart={true}
                    isAutoApi={true}
                    dataArray={dataArray?.items}
                    yourCart={true}
                  ></ShoppingListComponent>
                )}
                {currentDevice === "Mobile" && (
                  <CartViewMobile
                    reviewOrder={false}
                    isCart={true}
                    handleRefreshCallBack={handleRefreshCallBack}
                    dataArray={dataArray?.items}
                    isAutoApi={true}
                    yourCart={true}
                  ></CartViewMobile>
                )}
              </Grid>
              {dataArray?.items?.length > 0 && (
                <Grid item md={3} xs={12} className="orderSummary_yourCart">
                  <OrderSummary
                    isEstimated={true}
                    isCart={true}
                    handleRefreshCallBack={handleRefreshCallBack}
                    dataArray={dataArray}
                  />
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid
              container
              spacing={4}
              className="yourCart_bodyContainer"
              sx={{
                minHeight: "calc(100vh  - 387px) !important",
                //  minHeight: "760px",
              }}
            >
              <div className="center cartEmpty">
                <ShoppingCartCheckoutIcon
                  style={{ fontSize: 100 }}
                ></ShoppingCartCheckoutIcon>
                <div className="cartEmptyMessage carem">
                  <span>
                    Building your cart{Array(dots).fill(".").join("")}
                  </span>
                </div>
              </div>
            </Grid>
          )}
          <Grid
            item
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="yourCart_headerContainer"
          >
            <Footer></Footer>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
